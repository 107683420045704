/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Fonts */

@layer base {
  /* @font-face {
    font-family: 'Archivo';
    src: url('./fonts/ArchivoBlack-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  } */

  @font-face {
    font-family: 'CabinetGrotesk';
    src: url('./fonts/CabinetGrotesk-Variable.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'STIXGeneral';
    src: url('./fonts/STIXGeneral-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* @font-face {
    font-family: 'DMSerif';
    src: url('./fonts/DMSerifDisplay-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  } */

  /* @font-face {
    font-family: 'Rubik';
    src: url('./fonts/RubikBubbles-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  } */

  @font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi-Variable.ttf') format('truetype'),
      url('./fonts/Satoshi-VariableItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal, italic;
  }

  /* @font-face {
    font-family: 'Whisper';
    src: url('./fonts/Whisper-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  } */
}

p {
  font-family: 'Helvetica';
  font-size: 1rem;
}

h1 {
  font-family: 'STIXGeneral';
  font-size: 2.986rem;
}

h2 {
  font-family: 'STIXGeneral';
  font-size: 2.488rem;
}

h3 {
  font-family: 'STIXGeneral';
  font-size: 2.074rem;
}

h4 {
  font-family: 'STIXGeneral';
  font-size: 1.728rem;
}

h5 {
  font-family: 'STIXGeneral';
  font-size: 1.44rem;
}

h6 {
  font-family: 'STIXGeneral';
  font-size: 1.2rem;
}

#intro-name {
  font-size: 160px;
  letter-spacing: -15px;
  font-family: 'Baskerville';
  color: #294126;
}

#intro-name-mobile {
  font-size: 95px;
  letter-spacing: -9px;
  font-family: 'Baskerville';
  color: #294126;
}

#monstera-img-mobile {
  width: 100%;
  height: auto;
  position: relative;
  right: 0;
  transform: translateX(50%) scale(1.8);
}

#water-img-mobile {
  width: 100%;
  height: auto;
  position: relative;
  right: 0;
  transform: translateX(-50%) scale(1.3);
}


.grey {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

#landing-name-bottom-border::after {
  content: "";
  position: absolute;
  bottom: 28%;
  left: -5%;
  transform: translate(5%);
  width: 85%; /* Adjust this value to control the width of the border */
  height: 1px; /* Adjust this value to control the thickness of the border */
  background-color: #294126; /* Adjust the color of the border as needed */
}

#landing-star-svg {
  /* transform: translateY(220%); */
  margin-top: 112px;
  margin-right: 8px;
  animation: rotateAnimation ease-in-out infinite;
  animation-duration: 3s;
  animation-delay: 2s;
}

#landing-star-svg-mobile {
  /* transform: translateY(220%); */
  margin-right: 8px;
  animation: rotateAnimation ease-in-out infinite;
  animation-duration: 3s;
  animation-delay: 2s;
}

#image-row-empty-border::before {
  content: '';
  position: absolute;
  left: calc(100vw - 25%);
  width: 1px;
  height: 130%;
  transform: translateY(-20%);
  background-color: #294126;
}

#action-row-top-border::before {
  content: '';
  position: absolute;
  left: calc(100vw - 25%);
  width: 40%;
  height: 1px;
  background-color: #294126;
  transform: translateX(-68%);
}

#software-bottom-border {
  position: relative;
}

#software-bottom-border::after {
  content: "";
  position: absolute;
  /* transform: translateY(5%); */
  bottom: 0%;
  left: 0;
  width: 70%; /* Adjust this value to control the width of the border */
  height: 1px; /* Adjust this value to control the thickness of the border */
  background-color: #294126; /* Adjust the color of the border as needed */
}

#image-row-right-border img {
  position: absolute;
  z-index: 2;
}

#image-row-right-border {
  overflow: hidden;
  position: relative;
}

#monstera-img {
  width: 650px;
  height: auto;
  left: 10px;
  top: 15px;
}

#me-img {
  width: 180px;
  height: auto;
}

#neph-img {
  width: 180px;
  height: auto;
}

#about-transition-word {
  font-size: 24;
  font-family: 'STIXGeneral';
  cursor: pointer;
  transition: transform 0.5s ease !important;
}

#about-transition-word:hover {
  opacity: 0.5;
  transform: translateY(5px);
}

.bobbing-animation {
  animation: bobbing 2s infinite alternate ease-in-out;
}

@keyframes bobbing {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

.gradient-text {
  position: relative;
  display: inline-block;
  background: linear-gradient(to top, transparent 40%, #294126 55%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

#terrace-img {
  width: 250px;
  height: auto;
  margin-left: 10px;
  transform: translateY(-16%);
}

#water-img {
  filter: grayscale(100%);
  width: 250px;
  height: auto;
  z-index: 3;
  left: 630px;
  top: 75px;
  margin-right: 8px;
}

#butterfly-img {
  width: 150px;
  height: auto;
  z-index: 3;
  right: 30px;
  bottom: 150px;
}

#curated {
  font-size: 50px;
  transform: translateY(-60%);
}

#circle-svg {
  margin-right: 8px;
}

.intro-link {
  color: #513D2A;
  position: relative;
  cursor: pointer;
  font-family: 'STIXGeneral';
  transition: all 90ms ease;
}

.software-project::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #294126;
  transform: translateX(5%);
}

.software-project::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 1px;
  background-color: #294126;
}

.software-project-link-border::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #294126;
  transform: translateY(35%);
}

.intro-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #294126;
  transform: translateY(-5%);
}

#software-right-nav-border {
  position: relative;
}

#software-right-nav-border::after {
  content: '';
  position: absolute;
  left: 0;
  width: 1px;
  height: 80%;
  background-color: #294126;
  transform: translateY(-15);
}

.intro-link:hover {
  opacity: 50%;
  transition-delay: 50ms;
}

.randomRotate {
  animation: rotateAnimation ease-in-out 2;
}

@keyframes rotateAnimation {
  0% {
      transform: rotate(0deg); /* Slight counterclockwise start */
  }
  10% {
    transform: rotate(-5deg);
  }
  90% {
      transform: rotate(80deg); /* Clockwise rotation */
  }
  100% {
      transform: rotate(90deg); /* Final position */
  }
}

.fancy-li p {
  transition: color 0.5s ease;
}

.fancy-li:hover p {
  color: black;
}

.fancy-li:hover svg {
  transform: rotate(90deg);
  transition: transform 0.5s ease; /* Add smooth transition for animation */
}

.nav-tag {
  transition: color 0.25s ease;
  color: black;
}

.nav-tag:hover {
  color: #294126;
}

.nav-tag-deselected {
  transition: color 0.2s ease;
  opacity: 20%;
  color: #294126;
}

.nav-tag-deselected:hover {
  color: black;
}

